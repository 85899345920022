import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaInfo, FaPen, FaTrash } from "react-icons/fa";
import { Table, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import SidebarComponent from "./SidebarComponent"; 
import DetailModalInsuranceDeposits from "../Modals/DetailModalInsuranceDeposits";
import InsuranceDetailModalDeposit from "../Modals/InsuranceDetailModalDeposits";

const InsuranceDepositeList = () => {
  const [userData, setUserData] = useState([]);
  const { token } = useSelector((state) => state?.sliceReducer);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const fetchData = async () => {
    const headers = { Authorization: token };
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/insurance/get-deposits-insurance`,
        { headers }
      );
      setUserData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // Filtered Data based on search query
  const filteredData = userData.filter((user) => {
    const name = user?.userId?.name?.toLowerCase() || "";
    const phoneNumber = String(user?.userId?.phoneNumber || ""); 
    return name.includes(searchQuery.toLowerCase()) || phoneNumber.includes(searchQuery);
  });
  return (
    <>
      {showModal && (
        <InsuranceDetailModalDeposit
          showDetailsModal={showModal}
          close={() => setShowModal(false)}
          selectedUser={selectedUser}
        />
      )}
      <div style={{ display: "flex", height: "100vh" }}>
        <SidebarComponent />
        <div style={{ flex: 1, padding: "20px", marginLeft: "20%" }}>
          {showDetailsModal && (
            <DetailModalInsuranceDeposits
              showDetailsModal={showDetailsModal}
              close={() => setShowDetailsModal(false)}
              selectedUser={selectedUser}
            />
          )}

          <div className="search-container">
            <h1>Insurance Deposits</h1>
              
          </div>
          <Form.Control
                type="text"
                placeholder="Search by Name or Phone Number"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "300px" }}
              />
              &nbsp;
          <div className="user-table-data">
            <Table striped bordered hover size="sm" style={{ borderRadius: "5px" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>S.NO.</th>
                  <th style={{ textAlign: "center" }}>Name</th>
                  <th style={{ textAlign: "center" }}>Mobile Number</th>
                  <th style={{ textAlign: "center" }}>Gender</th>
                  <th style={{ textAlign: "center" }}>Amount</th>
                  <th style={{ textAlign: "center" }}>Insurance Details</th>
                  <th style={{ textAlign: "center" }}>User Info</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                    <td style={{ textAlign: "center" }}>{user?.userId?.phoneNumber}</td>
                    <td style={{ textAlign: "center" }}>
                      {user?.userId?.gender && (
                        <Button
                          variant={
                            user?.userId?.gender.toLowerCase() === "male"
                              ? "outline-warning"
                              : "outline-info"
                          }
                        >
                          {user?.userId?.gender}
                        </Button>
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>ብር-{user?.amount}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                        variant="secondary"
                        onClick={() => {
                          setSelectedUser(user);
                          setShowModal(true);
                        }}
                      >
                        <FaInfo />
                      </Button>
                    </td>
                    <td>
                      <Button
                        style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                        variant="primary"
                        onClick={() => {
                          setSelectedUser(user);
                          setShowDetailsModal(true);
                        }}
                      >
                        <FaInfo />
                      </Button>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button variant="primary">
                        <FaPen />
                      </Button>
                      &nbsp;
                      <Button variant="danger">
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="pagination-controls" style={{ textAlign: "right", marginRight: "20px" }}>
            <Button style={{ backgroundColor: "#FF914D", borderWidth: 0 }}>Previous</Button>
            &nbsp;
            <Button style={{ backgroundColor: "#FF914D", borderWidth: 0 }}>Next</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceDepositeList;
