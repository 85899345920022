import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { Table, Button, Form } from "react-bootstrap";
import { FaInfo } from "react-icons/fa";
import HandleModal from "../constants/helper";
import InsuranceDetailModal from "../Modals/InsuranceDetailModal";

const CarLoanListingComponent = () => {
  const [insuranceLoan, setInsuranceLoan] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { hasMorePages, currentPage, fetchpreviousPageData, fetchNextPageData } = HandleModal();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.yoonetinsurance.com/api/insurance/get-approved-reject-insurance?page=${currentPage}&limit=10`
        );
        setInsuranceLoan(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching insurance data:", error);
      }
    };
    fetchData();
  }, [currentPage]);

  const handleShowUserInfo = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // **Filter the insuranceLoan data based on search input**
  const filteredLoans = insuranceLoan.filter((user) => {
    const name = user?.userId?.name?.toLowerCase?.() || "";
    const phoneNumber = user?.userId?.phoneNumber?.toString?.() || "";
    
    return (
      name.includes(searchQuery.toLowerCase()) ||
      phoneNumber.includes(searchQuery)
    );
  });

  return (
    <>
      {showModal && (
        <InsuranceDetailModal
          showDetailsModal={showModal}
          close={() => setShowModal(false)}
          selectedUser={selectedUser}
        />
      )}
      <div>
        <div className="header-container">
          <SidebarComponent />
          <div className="user-container">
            <h1>Insurance Loans</h1>
            
            {/* Search Input */}
            <Form.Group className="mb-3" controlId="search">
              <Form.Control
                type="text"
                placeholder="Search by name or phone number"
                value={searchQuery}
                onChange={handleSearch}
                style={{width:"300px"}}
              />
            </Form.Group>

            <div className="user-table-data">
              <Table striped bordered hover size="sm" style={{ borderRadius: "5px" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>S.NO.</th>
                    <th style={{ textAlign: "center" }}>Name</th>
                    <th style={{ textAlign: "center" }}>Mobile Number</th>
                    <th style={{ textAlign: "center" }}>Loan Term</th>
                    <th style={{ textAlign: "center" }}>Vehicle Value</th>
                    <th style={{ textAlign: "center" }}>Status</th>
                    <th style={{ textAlign: "center" }}>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredLoans.length === 0 ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan="7">
                        No Insurance is available now
                      </td>
                    </tr>
                  ) : (
                    filteredLoans.map((user, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{user?.userId?.name || "N/A"}</td>
                        <td style={{ textAlign: "center" }}>{user?.userId?.phoneNumber || "N/A"}</td>
                        <td style={{ textAlign: "center" }}>{user?.loanTerm}</td>
                        <td style={{ textAlign: "center" }}>{user?.vehicleValue}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant={
                              user.status === "rejected" ? "danger" : "success"
                            }
                            style={
                              user.status === "in_progress"
                                ? {
                                    backgroundColor: "#FF914D",
                                    borderColor: "#FF914D",
                                    color: "white",
                                  }
                                : {}
                            }
                          >
                            {user.status === "in_progress"
                              ? "In Progress"
                              : user.status === "approved"
                              ? "Approved"
                              : user.status === "rejected"
                              ? "Rejected"
                              : user.status}
                          </Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                            variant="secondary"
                            onClick={() => handleShowUserInfo(user)}
                          >
                            <FaInfo />
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
            
            {!showModal && (
              <div className="pagination-controls" style={{ textAlign: "right", marginRight: "20px" }}>
                <Button
                  style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                  onClick={fetchpreviousPageData}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                &nbsp;
                <Button
                  style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                  onClick={fetchNextPageData}
                  disabled={!hasMorePages}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarLoanListingComponent;
