import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaInfo, FaPen, FaTrash } from "react-icons/fa";
import { Table, Button, Modal, Alert, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import DetailModal from "../Modals/DetailModal";
import HandleModal from "../constants/helper";
import AddDeposits from "./AddDeposite";

const DepositeList = () => {
  const [userData, setUserData] = useState([]);
  const { token } = useSelector((state) => state?.sliceReducer);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deletionStatus, setDeletionStatus] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState(null);
  const [editAmount, setEditAmount] = useState("");
  const [editTransactionId, setEditTransactionId] = useState("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchData = async () => {
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/insurance/get-all-deposits?page=${1}&limit=10`,
        { headers }
      );
      setUserData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleShowDetails = (user) => {
    setSelectedUser(user);
    setShowDetailsModal(true);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const close = () => {
    setOpen(false);
    fetchData();
  };

  const handleDeleteConfirmation = (id) => {
    setSelectedItemId(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (deposit) => {
    setSelectedDeposit(deposit);
    setEditAmount(deposit.amount);
    setEditTransactionId(deposit.transactionId);
    setShowEditModal(true);
  };

  const handleEditSubmit = async () => {
    const headers = {
      Authorization: token,
    };
    try {
      await axios.post(
        `https://api.yoonetinsurance.com/api/insurance/edit-deposits?id=${selectedDeposit._id}`,
        {
          amount: editAmount,
          transactionId: editTransactionId,
        },
        { headers }
      );
      setShowEditModal(false);
      fetchData(); // Refresh data after successful edit
    } catch (error) {
      console.error("Error updating deposit:", error);
    }
  };

  const handleDelete = async () => {
    const headers = {
      Authorization: token,
    };
    try {
      await axios.delete(
        `https://api.yoonetinsurance.com/api/insurance/delete-deposits?id=${selectedItemId}`,
        { headers }
      );
      setShowDeleteModal(false);
      setDeletionStatus("success");
      fetchData();
    } catch (error) {
      console.error("Error deleting deposit:", error);
      setDeletionStatus("error");
    }
  };

  return (
    <>
      {showDetailsModal && (
        <DetailModal
          showDetailsModal={showDetailsModal}
          close={() => {
            setShowDetailsModal(false);
          }}
          selectedUser={selectedUser}
        />
      )}
      <div className="Deposite_List">
        {open && (
          <AddDeposits
            close={() => {
              close();
            }}
          />
        )}

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {deletionStatus === "success" && (
          <Alert
            variant="success"
            onClose={() => setDeletionStatus(null)}
            dismissible
          >
            Deposit deleted successfully.
          </Alert>
        )}
        {deletionStatus === "error" && (
          <Alert
            variant="danger"
            onClose={() => setDeletionStatus(null)}
            dismissible
          >
            Error deleting deposit. Please try again.
          </Alert>
        )}

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Deposit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  value={editAmount}
                  onChange={(e) => setEditAmount(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="editTransactionId">
                <Form.Label>Transaction ID</Form.Label>
                <Form.Control
                  type="text"
                  value={editTransactionId}
                  onChange={(e) => setEditTransactionId(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="search-container">
          <h1>Deposits</h1>
          <div className="user-search-container">
            <Button
              variant="primary"
              onClick={() => {
                setOpen(true);
              }}
              style={{ backgroundColor: "#FF914D", borderWidth: 0, width: 200 }}
            >
              Add Deposit
            </Button>
          </div>
        </div>
        <div className="user-table-data">
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ borderRadius: "5px" }}
          >
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>S.NO.</th>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Mobile Number</th>
                <th style={{ textAlign: "center" }}>Gender</th>
                <th style={{ textAlign: "center" }}>Amount</th>
                <th style={{ textAlign: "center" }}>User Info</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                  <td style={{ textAlign: "center" }}>
                    {user?.userId?.phoneNumber}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {user?.userId?.gender && (
                      <Button
                        variant={
                          user?.userId?.gender.toLowerCase() === "male"
                            ? "outline-warning"
                            : "outline-info"
                        }
                      >
                        {user?.userId?.gender}
                      </Button>
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}> ብር-{user?.amount}</td>
                  <td>
                    <Button
                      style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                      variant="primary"
                      onClick={() => handleShowDetails(user?.userId)}
                    >
                      <FaInfo />
                    </Button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button variant="primary" onClick={() => handleEdit(user)}>
                      <FaPen />
                    </Button>
                    &nbsp;
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteConfirmation(user._id)}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div
          className="pagination-controls"
          style={{ textAlign: "right", marginRight: "20px" }}
        >
          <Button style={{ backgroundColor: "#FF914D", borderWidth: 0 }}>
            Previous
          </Button>
          &nbsp;
          <Button style={{ backgroundColor: "#FF914D", borderWidth: 0 }}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default DepositeList;
