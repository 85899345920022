import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaInfo, FaPen, FaTrash } from "react-icons/fa";
import { Table, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import DetailModalDeposits from "../Modals/DetailModalDeposits";
import AddDeposits from "./AddDeposite";
import SidebarComponent from "./SidebarComponent";
import FinancialDetailModal from "../Modals/FinancialDetailModal";

const FinancialDepositeList = () => {
  const [userData, setUserData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // 🔹 Added state for search
  const { token } = useSelector((state) => state?.sliceReducer);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/insurance/get-deposits-financial`,
        { headers: { Authorization: token } }
      );
      setUserData(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleShowUserInfo = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 🔹 Filter Data Based on Search Query
  const filteredData = userData.filter((user) => {
    const name = user?.userId?.name?.toLowerCase() || "";
    const phoneNumber = String(user?.userId?.phoneNumber || ""); // Ensure it's a string

    return (
      name.includes(searchQuery.toLowerCase()) ||
      phoneNumber.includes(searchQuery)
    );
  });

  return (
    <>
      {showModal && (
        <FinancialDetailModal
          showDetailsModal={showModal}
          close={handleCloseModal}
          selectedUser={selectedUser}
        />
      )}

      <div style={{ display: "flex" }}>
        {/* Sidebar */}
        <SidebarComponent />

        {/* Main Content */}
        <div style={{ flex: 1, padding: "20px", marginLeft: "20%" }}>
          <h1>Financial Deposits</h1>

          {/* 🔹 Search Input */}
          <Form.Control
            type="text"
            placeholder="Search by name or phone number..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: "15px", width: "300px" }}
          />

          {/* Table */}
          <Table striped bordered hover size="sm" style={{ borderRadius: "5px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>S.NO.</th>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Mobile Number</th>
                <th style={{ textAlign: "center" }}>Gender</th>
                <th style={{ textAlign: "center" }}>Amount</th>
                <th style={{ textAlign: "center" }}>Financial Info</th>
                <th style={{ textAlign: "center" }}>User Info</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "center" }}>{user?.userId?.name}</td>
                  <td style={{ textAlign: "center" }}>{user?.userId?.phoneNumber}</td>
                  <td style={{ textAlign: "center" }}>
                    {user?.userId?.gender && (
                      <Button
                        variant={
                          user?.userId?.gender.toLowerCase() === "male"
                            ? "outline-warning"
                            : "outline-info"
                        }
                      >
                        {user?.userId?.gender}
                      </Button>
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}> ብር-{user?.amount}</td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      variant="secondary"
                      style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                      onClick={() => handleShowUserInfo(user?.financialLoanId)}
                    >
                      <FaInfo />
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={{ backgroundColor: "#FF914D", borderWidth: 0 }}
                      variant="primary"
                      onClick={() => handleShowUserInfo(user)}
                    >
                      <FaInfo />
                    </Button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button variant="primary">
                      <FaPen />
                    </Button>
                    &nbsp;
                    <Button variant="danger">
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default FinancialDepositeList;
