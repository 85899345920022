import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddDeposits = ({ close }) => {
  const [insuranceUsers, setInsuranceUsers] = useState([]);
  const [financialUsers, setFinancialUsers] = useState([]);
  const { token } = useSelector((state) => state?.sliceReducer);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loanType, setLoanType] = useState(""); // Track loan type

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const headers = { Authorization: token };
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/user/get-all-approved-loan-users`,
        { headers }
      );
      console.log("Fetched Users:", response?.data?.data);
      setFinancialUsers(response?.data?.data?.financial || []);
      setInsuranceUsers(response?.data?.data?.insurance || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
      name: "",
      email: "",
      phoneNumber: "",
      amount: "",
      policyNumber: "",
      loanType: "",
      financialLoanId: "",
      insuranceLoanId: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError("Amount must be a number")
        .positive("Amount must be greater than zero")
        .required("Amount is required"),
      policyNumber: Yup.string().required("Policy Number is required"),
    }),
    onSubmit: async (values) => {
      console.log("Form Submitted with values:", values);

      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          `https://api.yoonetinsurance.com/api/insurance/create-deposits`,
          {
            amount: values.amount,
            policyNumber: values.policyNumber,
            loanType: values.loanType, // Send loanType
            userId: values.userId,
            financialLoanId: values.financialLoanId || null, // Send financialLoanId if finance is selected
            insuranceLoanId: values.insuranceLoanId || null, // Send insuranceLoanId if insurance is selected
          },
          { headers }
        );

        console.log("API Response:", response?.data);
        alert("Deposit created successfully.");
        close();
      } catch (error) {
        console.error("API Error:", error.response?.data);
        alert(error.response?.data?.message || "Error processing request");
      }
    },
  });

  const handleUserSelection = (e, type) => {
    const selectedUser =
      type === "financial"
        ? financialUsers.find((user) => user._id === e.target.value)
        : insuranceUsers.find((user) => user._id === e.target.value);

    setSelectedUser(selectedUser);

    if (selectedUser) {
      
      const selectedLoanType = type === "financial" ? "finance" : "insurance";

      formik.setValues({
        ...formik.values,
        userId: selectedUser.userId, 
        name: selectedUser.name || "",
        email: selectedUser.email || "",
        phoneNumber: selectedUser.mobileNumber || "",
        policyNumber: selectedUser.policyNumber || "",
        loanType: selectedLoanType, 
        financialLoanId: type === "financial" ? selectedUser._id : "", 
        insuranceLoanId: type === "insurance" ? selectedUser._id : "", 
      });

      setLoanType(selectedLoanType);
    }
  };

  return (
    <Modal show={true} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Add Deposits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
          {/* Financial User Selection */}
          <div>
            <label className="inputTextModal">Financial User</label>
            <select
              className="inputTextFieldModal"
              id="financialUserId"
              name="userId"
              onChange={(e) => handleUserSelection(e, "financial")}
              value={formik.values.loanType === "finance" ? formik.values.userId : ""}
            >
              <option value="">Select a user</option>
              {financialUsers.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name} - {user.mobileNumber}
                </option>
              ))}
            </select>
            {formik.touched.userId && formik.errors.userId && <div>{formik.errors.userId}</div>}
          </div>

          {/* Insurance User Selection */}
          <div>
            <label className="inputTextModal">Insurance User</label>
            <select
              className="inputTextFieldModal"
              id="insuranceUserId"
              name="userId"
              onChange={(e) => handleUserSelection(e, "insurance")}
              value={formik.values.loanType === "insurance" ? formik.values.userId : ""}
            >
              <option value="">Select a user</option>
              {insuranceUsers.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name} - {user.mobileNumber}
                </option>
              ))}
            </select>
            {formik.touched.userId && formik.errors.userId && <div>{formik.errors.userId}</div>}
          </div>

          {/* Readonly User Info */}
          <div>
            <label className="inputTextModal">Name</label>
            <input className="inputTextFieldModal" type="text" value={formik.values.name} readOnly />
          </div>

          <div>
            <label className="inputTextModal">Email</label>
            <input className="inputTextFieldModal" type="text" value={formik.values.email} readOnly />
          </div>

          <div>
            <label className="inputTextModal">Phone</label>
            <input className="inputTextFieldModal" type="text" value={formik.values.phoneNumber} readOnly />
          </div>

          {/* Form Inputs */}
          <div>
            <label className="inputTextModal">Policy Number</label>
            <input
              className="inputTextFieldModal"
              type="text"
              id="policyNumber"
              name="policyNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.policyNumber}
            />
            {formik.touched.policyNumber && formik.errors.policyNumber && <div>{formik.errors.policyNumber}</div>}
          </div>

          <div>
            <label className="inputTextModal">Amount</label>
            <input
              className="inputTextFieldModal"
              type="text"
              id="amount"
              name="amount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amount}
            />
            {formik.touched.amount && formik.errors.amount && <div>{formik.errors.amount}</div>}
          </div>

          <button className="loginButtonModal" style={{ marginTop: "2%" }} type="submit">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddDeposits;
