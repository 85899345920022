import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { renderImages } from "../constants/helper";

const DetailModalInsuranceDeposits = ({
  close,
  showDetailsModal,
  selectedUser,
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [myUrl, setUrl] = useState("");

  const onImageClick = (url) => {
    setModal(true);
    setUrl(url);
  };

  return (
    <>
      {selectedUser && (
        <div className="detailmain">
          <div className="headerDetail">
            <h2>User Detail</h2>
            <Button
              variant="secondary"
              onClick={close}
              style={{
                backgroundColor: "#FF914D",
                border: "#FF914D",
                height: 40,
                margin: 10,
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 10,
            }}
          >
            <img
              className="userImages"
              src={
                selectedUser?.userId?.image ||
                "https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
              }
            />

            <div>
              <p>
                <strong>Name:</strong> {selectedUser?.userId?.name}
              </p>
              <p>
                <strong>Deposit Amount:</strong> {selectedUser?.amount}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser?.userId?.email}
              </p>
              <p>
                <strong>Is Verified:</strong> {selectedUser?.userId?.isVerified}
              </p>
              <p>
                <strong>Phone Number:</strong>{" "}
                {selectedUser?.userId?.phoneNumber}
              </p>

              <p>
                <strong>Zone:</strong> {selectedUser?.userId?.zone}
              </p>
              <p>
                <strong>City:</strong> {selectedUser?.userId?.city}
              </p>
              <p>
                <strong>Sub City:</strong> {selectedUser?.userId?.subCity}
              </p>
              <p>
                <strong>House Number:</strong>{" "}
                {selectedUser?.userId?.houseNumber}
              </p>

              <p>
                <strong>Land Line Number:</strong>{" "}
                {selectedUser?.userId?.landlineNumber}
              </p>
            </div>

            <div>
              <p>
                <strong>Father Name:</strong> {selectedUser?.userId?.fName}
              </p>
              <p>
                <strong>Country Code:</strong>{" "}
                {selectedUser?.userId?.countryCode}
              </p>
              <p>
                <strong>Gender:</strong> {selectedUser?.userId?.gender}
              </p>
              <p>
                <strong>Date Of Birth:</strong>{" "}
                {selectedUser?.userId?.dateOfBirth}
              </p>
              <p>
                <strong>Nationality:</strong>{" "}
                {selectedUser?.userId?.nationality}
              </p>

              <p>
                <strong>Total Male Number:</strong>{" "}
                {selectedUser?.userId?.totalMaleNumber}
              </p>
              <p>
                <strong>Total Female Number:</strong>{" "}
                {selectedUser?.userId?.totalFemaleNumber}
              </p>
              <p>
                <strong>Type of Identification Card:</strong>{" "}
                {selectedUser?.userId?.typeOfIdentificationCard}
              </p>
              <p>
                <strong>Id Number:</strong> {selectedUser?.userId?.idNumber}
              </p>
            </div>

            <div>
              <p>
                <strong>Education Status:</strong>{" "}
                {selectedUser?.userId?.educationStatus}
              </p>
              <p>
                <strong>Country:</strong> {selectedUser?.userId?.country}
              </p>
              <p>
                <strong>Current Address:</strong>{" "}
                {selectedUser?.userId?.currentAddress}
              </p>
              <p>
                <strong>Region:</strong> {selectedUser?.userId?.region}
              </p>

              <p>
                <strong>Grand Mother Name:</strong>{" "}
                {selectedUser?.userId?.gMName}
              </p>
              <p>
                <strong>Marital Status:</strong>{" "}
                {selectedUser?.userId?.maritalStatus}
              </p>
              <p>
                <strong>Full Name of Spouse:</strong>{" "}
                {selectedUser?.userId?.fullNameOfSpouse}
              </p>
              <p>
                <strong>Total Family Number:</strong>{" "}
                {selectedUser?.userId?.totalFamilyNumber}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              marginLeft: 190,
            }}
          >
            <p>
              <strong>Facial Pictures:</strong>
            </p>
            {renderImages(selectedUser?.userId?.facialPicture, onImageClick)}

            <p>
              <strong>Identification Cards:</strong>
            </p>
            {renderImages(
              selectedUser?.userId?.identificationCard,
              onImageClick
            )}

            <p>
              <strong>Marriage Certificates:</strong>
            </p>
            {renderImages(
              selectedUser?.insuranceLoanId?.drivingLicenceImage,
              onImageClick
            )}
          </div>
        </div>
      )}

      <Modal show={modal}>
        <Modal.Header
          closeButton
          onHide={() => {
            setModal(false);
          }}
        >
          {/* <Modal.Title>Create Account</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              style={{ height: 540, width: "100%", alignSelf: "center" }}
              src={myUrl.trim()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailModalInsuranceDeposits;
